import { PageProps, graphql } from 'gatsby'
import { FC } from 'react'

import Breadcrumbs from '../../components/Breadcrumbs'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO/SEO'
import { useIntlLabel } from '../../intl'
import ServiceListSection from './ServiceListSection'
import { ServiceCategoryTemplateQuery } from './__generated__/ServiceCategoryTemplateQuery'

interface IServiceTemplateProps extends PageProps {
    data: ServiceCategoryTemplateQuery
}

const ServiceCategoryTemplate: FC<IServiceTemplateProps> = ({ data, location }) => {
    const getIntlLabel = useIntlLabel()
    const splicePath = location.pathname.split('/')
    const path = splicePath[splicePath.length - 2]

    return (
        <Layout>
            <SEO
                title={getIntlLabel(data.strapiServiceCategory, 'categoryName') ?? ''}
                description={getIntlLabel(data.strapiServiceCategory, 'categoryDescription') ?? ''}
                pageUrl={`/services/${data?.strapiServiceCategory?.categorySlug}`}
                isArticle
            />
            <Breadcrumbs current={getIntlLabel(data.strapiServiceCategory, 'categoryName') ?? ''} />
            <ServiceListSection
                path={path}
                categories={data.allStrapiServiceCategory}
                sectionTitle={getIntlLabel(data.strapiServiceSection, 'usefulServiceLabel')}
                services={data?.strapiServiceCategory?.services ?? Array()}
                categorySlug={data?.strapiServiceCategory?.categorySlug}
                categoryName={getIntlLabel(data?.strapiServiceCategory, 'categoryName')}
                categoryDescription={getIntlLabel(
                    data?.strapiServiceCategory,
                    'categoryDescription',
                )}
            />
        </Layout>
    )
}

export const query = graphql`
    query ServiceCategoryTemplateQuery($id: String) {
        strapiServiceCategory(id: { eq: $id }) {
            categorySlug
            categoryDescription
            categoryDescription_mg
            categoryName
            categoryName_mg
            strapiId
            services {
                application {
                    applicationServeiceName
                    serviceApplicationUrl
                }
                serviceTitle
                serviceTitle_mg
                serviceSubTitle
                serviceSubTitle_mg
                serviceSlug
                id
            }
        }
        strapiServiceSection {
            usefulServiceLabel
            usefulServiceLabel_mg
            attachedMinistriesTitle
            attachedMinistriesTitle_mg
        }
        allStrapiServiceCategory {
            nodes {
                categoryName
                categoryName_mg
                categoryDescription
                categoryDescription_mg
                categorySlug
                categorySlug_mg
                id
                icon {
                    url
                }
            }
        }
    }
`

export default ServiceCategoryTemplate
